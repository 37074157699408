<template>
    <v-form>
        <v-dialog
            v-model="createCompanyDialog"
            persistent
            max-width="600px"
        >
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    color="primary"
                    dark
                    v-bind="attrs"
                    v-on="on"
                >
                    {{ title }}
                </v-btn>
            </template>
            <v-card>
                <v-card-title>
                    <span class="headline">{{ title }}</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-text-field
                            label="Contract"
                            v-model="form.contract"
                            required
                            :rules="[v => !!v || 'Contract Number is required.']"
                        ></v-text-field>
                        <v-text-field
                            label="Name"
                            v-model="form.name"
                        ></v-text-field>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="blue darken-1"
                        text
                        @click="createCompanyDialog = false"
                    >
                        Close
                    </v-btn>
                    <v-btn
                        color="blue darken-1"
                        text
                        @click="updateCompany"
                    >
                        Save
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-form>
</template>

<script>

export default {
  props: {
    title: String,
    data: Object,
    create: Boolean
  },

  data () {
    return {
      myData: 'my|your Data',
      createCompanyDialog: false,
      form: {
        contract: '',
        name: ''
      }
    }
  },

  created () {
    this.form = JSON.parse(JSON.stringify(this.data))
  },

  methods: {
    updateCompany () {
      if (this.create !== true) {
        this.$emit('updatedCompany', this.form)
        this.createCompanyDialog = false
      } else {
        this.$emit('createdCompany', this.form)
        this.createCompanyDialog = false
      }
    }
  }
}
</script>
