<template>
    <div v-if="user">
        <v-card :elevation="5">
            <v-card-title class="subheading font-weight-bold">
                <span class="mr-3">User {{user.name}} </span>
                  <Update :data="user" @updatedCompany="update" />
            </v-card-title>

            <v-divider></v-divider>

            <v-list dense>
                <div v-for="key in keys" :key="key.key">
                    <v-list-item v-if="$dotProp.has(user, key.key)">
                        <v-row>
                            <v-col cols="3">
                                <v-list-item-content class="font-weight-bold">{{ key.label }}:</v-list-item-content>
                            </v-col>
                            <v-col>
                                <v-list-item-content class="align-end">
                                    {{ $dotProp.get(user, key.key) || '---' }}
                                </v-list-item-content>
                            </v-col>
                        </v-row>
                    </v-list-item>
                </div>
            </v-list>

            <v-divider></v-divider>

            <!-- <v-card-actions>
                <v-btn
                    color="deep-purple lighten-2"
                    text
                    :to="{name: 'companys.show', params: {company: company.promo_code}}"
                >
                    Details
                </v-btn>
            </v-card-actions> -->
        </v-card>
    </div>
</template>

<script>
import Update from './Update'

export default {
  name: 'Show',

  components: {
    Update
  },

  data () {
    return {
      dialog: false,
      user: null,
      activity: [],
      search: '',
      loading: true,
      stage: process.env.VUE_APP_STAGE,
      keys: [
        {
          key: 'id',
          label: 'ID'
        },
        {
          key: 'name',
          label: 'Name'
        },
        {
          key: 'email',
          label: 'EMAIL'
        }
      ],
      headers: [
        {
          value: 'id',
          text: 'ID'
        },
        {
          value: 'contract',
          text: 'Contract'
        },
        {
          value: 'name',
          text: 'Game'
        }
      ]
    }
  },

  mounted () {
    console.log(this.$route.params)
    console.log(this.$route)

    this.$axios.get(`api/users/${this.$route.params.id}`)
      .then(response => {
        this.user = response.data
      })
  },

  methods: {
    update (user) {
      this.user = user
    },
    formatCurrency (value) {
      return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'EUR' }).format(value) // '$100.00'
    }
  }
}
</script>

<style scoped>

</style>
