<template>
    <Form
        :title="title"
        :data="data"
        @updatedCompany="updateCompany"
        :showDialog="showDialog"
        ></Form>
</template>

<script>
import Form from './Form'

export default {
  components: {
    Form
  },

  props: {
    data: Object
  },

  data () {
    return {
      title: 'Update Company',
      myData: 'my|your Data',
      createCompanyDialog: false,
      form: {
        name: String
      },
      showDialog: true
    }
  },

  methods: {
    updateCompany (data) {
      this.$axios.put(`api/companies/${data.id}`, data).then(response => {
        this.createCompanyDialog = false
        this.showDialog = false
        this.$emit('updatedCompany', response.data)
      }).catch(
        result => {
          this.$emit('updatedError')
        }
      )
    }
  }
}
</script>
